import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '@app/services/api/api.service';
import { StoreService } from '@app/services/store/store.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { RoutePath } from '@app/app.routes';
import { IUserToken } from '@app/models/user';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Subject, takeUntil } from 'rxjs';
import { LayoutService } from '@app/services/app.layout.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [],
})
export class LoginComponent implements OnInit, OnDestroy {
  private localdb: Storage = window.localStorage;
  public submitted = false;
  loginForm: UntypedFormGroup;
  private $ngDestroy = new Subject<void>();
  blockContent = false;
  isCaptchaChecked: boolean;
  constructor(
    private router: Router,
    private api: ApiService,
    private store: StoreService,
    private formBuilder: UntypedFormBuilder,
    private messageService: MessageService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private layoutService: LayoutService
  ) { }

  ngOnInit() {
    const username = this.store.getUsername();
    // if (this.localdb.getItem(DBKEYS.TOKEN) && JSON.parse(this.localdb.getItem(DBKEYS.TOKEN)).rememberedIf) {
    //   this.router.navigateByUrl(RouteNames.dashboard);
    // }
    // this.localdb.clear();

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      rememberedIf: [false],
    });

    if (username) this.loginForm.get('username').setValue(username);

    if (this.store.retrieveToken() != null) {
      const token = this.store.retrieveToken();
      if (token.id != null && token.id != '') {
        this.api.retrieveLoggedUserOb(token).subscribe(user => {
          if (user) this.router.navigate([RoutePath.dashboard]);
          else {
            this.localdb.clear();
            this.store.setUsername(username);
          }
        },
          error => {
            this.localdb.clear();
            this.store.setUsername(username);
          });
      }
    }

    // this.store.state$.pipe(pluck('isLoginFailed'), take(1)).subscribe(isLoginFailed => {
    //   if (isLoginFailed) {
    //     this.submitted = false
    //     this.store.setIsLoginFailed(false)
    //   }
    // })

    this.store.state$.pipe(takeUntil(this.$ngDestroy)).subscribe(state => {
      if (state.isLoginFailed) {
        this.submitted = false;
        this.store.setIsLoginFailed(false);
      }
    });
  }

  get f() {
    return this.loginForm.controls;
  }

  showWarn = (msg: string) => {
    this.messageService.add({
      key: 'tst',
      severity: 'warn',
      summary: 'Warning',
      detail: msg,
    });
  };
  showError = (msg: string) => {
    this.messageService.add({
      key: 'tst',
      severity: 'error',
      summary: 'Error',
      detail: msg,
    });
  };
  showSuccess = (msg: string) => {
    this.messageService.add({
      key: 'tst',
      severity: 'success',
      summary: 'Success',
      detail: msg,
    });
  };

  onLoginSubmit() {
    if (this.loginForm.invalid) {
      return;
    }

     this.recaptchaV3Service.execute('importantAction').pipe(
      takeUntil(this.$ngDestroy)).subscribe({
      next: (token) => {
        if (!token?.length) {
          this.showWarn(
            'Please try again.'
          )
        }
        else {

          this.blockContent = true;

          this.submitted = true;

          this.api
            .login({ username: this.f.username.value, password: this.f.password.value }, this.f.rememberedIf.value)
            .subscribe((res: IUserToken) => {
              // @ts-ignore
              if (res.statusCode && res.statusCode === 500) {
                // @ts-ignore
                this.showWarn(res.name);
                this.submitted = false;
                this.blockContent = false;
                return;
              }
              // @ts-ignore
              if (res.statusCode && res.statusCode === 401) {
                // @ts-ignore
                this.showWarn(res.message);
                this.submitted = false;
                this.blockContent = false;
                return;
              }
              this.api.retrieveLoggedUserOb(res).subscribe(async (user) => {
                if (user) {
                  // await this.layoutService.setUISettingFromUserInfo(user);
                }
                this.blockContent = false;
                if (res) {
                  this.store.storePassword(this.f.password.value);
                  if (this.f.rememberedIf.value) {
                    this.store.setUsername(this.f.username.value);
                  }

                  this.router.navigate([RoutePath.dashboard]);
                }

                this.showSuccess('User logged in successfully');
                this.submitted = false;
              },

                (err: any) => {
                  this.blockContent = false;
                  this.submitted = false;
                },
                () => {
                  this.blockContent = false;
                  this.submitted = false;
                });
            },
              error => {
                this.blockContent = false;
                this.submitted = false;
              });
        }
      }
    })
  }

  ngOnDestroy(): void {
    this.$ngDestroy?.next();
    this.$ngDestroy?.complete();
  }
}
