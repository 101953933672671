export const environment = {
  production: true,
  api: {
    core: {
      uri: 'https://ats-demo.call-matrix.com',
      port: '9443',
      path: '/api/v1',
    },
  },
  softphone: {
    scheme: 'wss',
    server: 'ats-demo.call-matrix.com',
    port: '6061',
    path: '/ws',
    sipDomain: 'tenant_',
  },
  stripe: {
    key: 'pk_test_51MExjqAHXo4nJ6TuOMsdZZjrobRFdyhcG044kPgY2xH7ZeNDtclGWTCnzOuuL77WR8xoRyeHO3qtwr6CdvwRZwXn00CFeVtSyh',
  },
  recaptcha: {
    key: '6LdAYI4pAAAAAFQQLBVJzDlOWWZgLsqpu5UrLitX'
  }
};
